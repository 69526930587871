export default {
    methods: {
        isRowValid(categories, row) {
            let valid = true;
            categories.forEach(category => {
                if (
                    this.isRequired(category) &&
                    (row[category.name].tags.filter(x => x.value).length < 1)
                ) {
                    valid = false;
                }
            });
            return valid;
        },
        getNotValidItems(categories, row){
            let items = [];
            categories.forEach(category => {
                if(this.isRequired(category) && (row[category.name].tags.filter(x => x.value).length < 1)){
                    items.push(category.name);
                }
            });

            return items.join(', ');
        },
        isRequired(category) {
            return category.validation_rules.find(e => e.rule === "required")
                ? true
                : false;
        }
    }
};
