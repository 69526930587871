<template>
    <div>
        <v-edit-dialog
            ref="dialog"
            :return-value="item[column].formatted_value"
            @save="save('save')"
            @open="open"
            @cancel="handleDialogClose"
            @close="handleDialogClose"
        >
            <v-icon
                v-if=" value === '' || value === null"
                small
                :color="isRequired() ? 'error' : ''"
                class="mr-2"
            >
                mdi-pencil
            </v-icon>
            <template v-if="category.tag_category_type !== 'text' || !value">
                    <span v-if="item[column].hasOwnProperty('tags') && item[column]['tags'].length > 0 && item[column]['tags'][0].type === 'Overwrite'" style="color:#ff5722"> {{value}} </span>
                    <span v-if="item[column].hasOwnProperty('tags') && item[column]['tags'].length > 0 && item[column]['tags'][0].type === 'Apply'" style="color:#009806"> {{value}} </span>
                                        <span v-if="item[column].hasOwnProperty('tags') && item[column]['tags'].length > 0 && item[column]['tags'][0].type !== 'Overwrite' && item[column]['tags'][0].type !== 'Apply' && !item[column]['tags'][0].is_new" > {{value}} </span>
                    <span v-if="item[column].hasOwnProperty('tags') && item[column]['tags'].length > 0 && item[column]['tags'][0].type !== 'Overwrite' && item[column]['tags'][0].type !== 'Apply' && item[column]['tags'][0].is_new" style="color: rgb(209 0 0);font-weight: 700;"> {{value}} </span>
            </template>
            <template v-if="category.tag_category_type === 'text' && value">
                    <template v-for="(v,i) in item[column]['tags']">
                        <template v-if="v.formatted_value">
                            <span v-if="i % 2 === 0 && v.type === 'Apply'" :key="i" style="color:#009806"> {{v.formatted_value}} </span>
                            <span v-if="i % 2 !== 0 && v.type === 'Apply'" :key="i" style="color:#1f8823"> {{v.formatted_value}} </span>
                            <span v-if="i % 2 === 0 && v.type === 'Overwrite'" :key="i" style="color:#ff5722"> {{v.formatted_value}} </span>
                            <span v-if="i % 2 !== 0 && v.type === 'Overwrite'" :key="i" style="color:#b12a00"> {{v.formatted_value}} </span>
                            <span v-if="i % 2 === 0 && v.type !== 'Overwrite' && v.type !== 'Apply'" :key="i" style="color:#b600ff"> {{v.formatted_value}} </span>
                            <span v-if="i % 2 !== 0 && v.type !== 'Overwrite' && v.type !== 'Apply'" :key="i" style="color:blue"> {{v.formatted_value}} </span>
                        </template>

                    </template>
            </template>
            <template v-slot:input>
                <v-form @submit.prevent v-model="isValid">
                    <v-text-field
                        ref="text-field"
                        v-if="fieldType === 'text' || fieldType === 'number'"
                        v-model="tValue"
                        :rules="validations.getRuleFunctions(category)"
                        label="Edit"
                        single-line
                        autofocus
                        :type="fieldType"
                    ></v-text-field>
                    <ComboBox
                        ref="combobox"
                        :key="comboBoxKey"
                        v-if="fieldType === 'combobox'"
                        v-model="tValue"
                        :items="comboboxItems"
                        :loading="iscomboboxLoading"
                        autofocus
                    />
                </v-form>
                    <v-row
                        align="center"
                        justify="space-around"
                    >
                     <v-btn text @click="cancel" >Cancel</v-btn>
                     <v-btn text @click="save('overwrite')" color="warning">Overwrite</v-btn>
                     <v-btn text @click="save('apply')" color="success">Apply to empty</v-btn>
                     <v-btn text @click="save('save')" color="primary" type="submit">Save</v-btn>
                    </v-row>
            </template>
        </v-edit-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComboBox from "@/views/partials/form/ComboBox.vue";
import validations from "@/views/partials/form/Validations.vue";
import { SEARCH_TAGS_BY_CATEGORY } from "@/store/vendorOffer.module";
import { SET_FIELD_CHANGE } from "@/store/vendorOffer.module";
import { REFRESH_OFFERS_WITH_PARTS } from "@/store/vendorOfferSet.module";

import offerService from "@/common/offer.service";
export default {
    components: { ComboBox },
    props: ["item", "column", "category","value"],
    computed: {
        ...mapGetters(["selectOptionsByCategory"]),
        fieldType() {
            var res = "text";
            let type = this.category.tag_value_type;
            if (type === "varchar") {
                res = "combobox";
            } else if (type === "numeric") {
                res = "number";
            }
            return res;
        }
    },
    data() {
        return {
            validations: validations,
            applyToAll: false,
            comboBoxKey: 1,
            iscomboboxLoading: false,
            comboboxItems: [],
            isValid: false,
            tValue: ''
        };
    },
    methods: {
        searchTags() {
            if (this.selectOptionsByCategory(this.category.name) !== null) {
                this.iscomboboxLoading = false;
                this.comboboxItems = this.selectOptionsByCategory(
                    this.category.name
                );
            } else {
                this.iscomboboxLoading = true;
                this.$store
                    .dispatch(SEARCH_TAGS_BY_CATEGORY, this.category.name)
                    .then(items => {
                        this.comboboxItems = items;
                    })
                    .finally(() => (this.iscomboboxLoading = false));
            }
        },
        isRequired() {
            return this.category.validation_rules.find(
                e => e.rule === "required"
            )
                ? true
                : false;
        },
        save(type) {
            let item = this.item[this.column];
            item.formatted_value = this.tValue;
            this.$store.commit(SET_FIELD_CHANGE, false);
            if(type === 'overwrite' && this.isValid){
                this.$emit("overwrite", this.column, item.formatted_value);
            }

            if(type === 'apply' && this.isValid){
                this.$emit("apply", this.column, item.formatted_value);
            }

            if(type === 'save' && this.isValid){
                if(this.category.tag_category_type === 'text'){
                    if(!item.formatted_value){
                        item.tags = [];
                        this.$store.dispatch(REFRESH_OFFERS_WITH_PARTS);
                    }

                    if(item.tags.length === 0){
                        item.tags.push(offerService.setTag({start:null,value:item.formatted_value,formatted_value:item.formatted_value,type:''}));
                    }else{
                        let splitVal = item.formatted_value.split('|');
                        let tag = {};
                        let position = 0;
                        let tags = [];
                        item.tags.forEach((x,k) => {
                            let index = splitVal.indexOf(x.formatted_value);
                            if(index !== -1){
                                    tags.push(x);
                                    splitVal.splice(index,1);
                            }else{
                                tag = x;
                                position = k;
                            }
                        });

                        if(!tags.length && item.tags.length > 0){
                            tags = item.tags;
                            tags[tags.length-1].formatted_value = item.formatted_value;
                        }else{
                            tag.formatted_value = splitVal.join('|');
                            tags.splice(position,0,tag);
                        }
                        item.tags = tags;
                    }

                }else{
                    if(!item.tags.length){
                        item.tags.push(offerService.setTag({}));
                    }
                    item.tags[0].type = 'Edited';
                    if (item.tags[0].start === null) {
                        item.tags[0].value = item.formatted_value;
                        item.tags[0].formatted_value = item.formatted_value;
                    }else{
                        item.tags[0].formatted_value = item.formatted_value;
                    }

                    if(!item.tags[0].formatted_value){
                        this.$store.dispatch(REFRESH_OFFERS_WITH_PARTS);
                    }
                }
                this.$emit("save", this.column, item.formatted_value);
            }

            this.$refs.dialog.cancel();
        },
        open() {
            this.tValue = this.value;
            this.searchTags();
            this.comboBoxKey++;
        },
        cancel(){
            this.$refs.dialog.cancel();
        },
        handleDialogClose() {
          this.tValue = '';
          this.comboBoxKey++;
        }
    }
};
</script>
