<template>
<div>
  <v-card-actions>

    <v-spacer></v-spacer>
    <template v-if="updateId">
  <v-btn
        v-if="updateId"
        color="warning"
        v-on:click="back()"
        >Back</v-btn
      >
      <v-btn
        :disabled="offerTableRows.length === 0"
        color="success"
        v-on:click="update=false; checkIsValid();"
        >Save As New</v-btn
      >
      <v-btn
        :disabled="offerTableRows.length === 0"
        color="primary"
        v-on:click="
          update = true;
          checkIsValid();
        "
        >Update</v-btn
      >
    </template>
    <template v-if="!updateId">
      <v-btn
        :disabled="offerTableRows.length === 0"
        color="primary"
        v-on:click="checkIsValid()"
        >Store</v-btn
      >
    </template>
  </v-card-actions>
    <TPConfirm
      v-model="showConfirmReset"
      content="Reset all current progress?"
      @confirm="resetData"
    ></TPConfirm>
    <TPConfirm
      v-model="showConfirm"
      content="Have you double-checked all data?"
      @confirm="confirmProcess"
    ></TPConfirm>
    <TPConfirm
      v-model="showConfirmBack"
      content="All current changes will be lost."
      @confirm="confirmBack"
    ></TPConfirm>
</div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import {
    UPDATE_VENDOR_OFFER_SET,
    STORE_VENDOR_OFFER_SET,
    RESET_DATA
} from "@/store/vendorOfferSet.module";

import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import validationMixin from "@/views/tagProcessor/Mixins/validation.mixin";

export default {
    components: {
        TPConfirm
    },
    mixins: [validationMixin],
    props: ["updateId","showConfirmReset"],
    computed: {
        ...mapGetters([
            "vendorOfferSet",
            "tagCategories",
            "offerTableRows",
            "partOfNextSet",
            "partOfAll",
            "fieldChange"
        ]),
        ...mapState({ errors: state => state.vendorOffer.errors })
    },
    mounted(){
        setTimeout(x=>this.textHeight = parseInt(( document.getElementsByTagName('body')[0].offsetHeight-224)),100)
    },
    data() {
        return {
            errorSnack: false,
            showConfirm: false,
            errorMessage: null,
            textHeight:200,
            update:false,
            showConfirmBack:false
        };
    },
    methods: {
        checkIsValid() {
            this.errorSnack = false;
            let errors = "All fields with the red pensil icon are required.";
            this.offerTableRows.some((tagItem,i) => {
                if (!this.isRowValid(this.tagCategories, tagItem)) {
                    errors += 'OFFER: '+(i+1) + ' ('+this.getNotValidItems(this.tagCategories, tagItem)+')';
                    this.errorSnack = true;
                    this.$emit("toggleTab","tab-3");
                    this.$emit("showError",{offer:(i+1),error:errors});
                    return true;
                }
            });
            if (!this.errorSnack) {
                this.showConfirm = true;
            }
        },
        confirmProcess() {
            if(this.update){
                this.updateOffer();
            }else{
                this.storeOffer();
            }

        },
        storeOffer() {
             this.$emit("processing",true);
            let params = this.prepareOfferSet();
            this.$store
                .dispatch(STORE_VENDOR_OFFER_SET, params)
                .then(() => {
                     this.$emit("loadig",false);
                     this.$emit("processing",false);
                    this.$emit("stored");
                })
                .catch(response => {

                    console.error(response);
                     this.$emit("loadig",false);
                      this.$emit("processing",false);
                    this.errorSnack = true;
                    this.errorMessage = this.$t("GENERAL.UNEXPECTED_ERROR");
                    // TODO handle error
                });
        },
        updateOffer() {
             this.$emit("processing",true);
            let params = this.prepareOfferSet();
            this.$store
                .dispatch(UPDATE_VENDOR_OFFER_SET,{id:this.updateId,params,params})
                .then(() => {
                      this.$emit("loadig",false);
                      this.$emit("processing",false);
                      this.$emit("stored");
                })
                .catch(response => {
                    console.error(response);
                     this.$emit("loadig",false);
                       this.$emit("processing",false);
                    this.errorSnack = true;
                    this.errorMessage = this.$t("GENERAL.UNEXPECTED_ERROR");
                    // TODO handle error
                });
        },
        prepareOfferSet()
        {
            // TODO add separations
            let params = this.vendorOfferSet;
            params.offers = [];
            params.separations = [];
            params.categorized_separations = [];

              this.$emit("loadig",true);

            this.offerTableRows.forEach(tagItem => {
                let offer = {
                    id:tagItem.id || null,
                    raw: tagItem.raw,
                    tags: {}
                };

                this.tagCategories.forEach(cat => {
                    if(tagItem[cat.name]['tags']){
                        tagItem[cat.name]['tags'].forEach(x => {
                            if (
                                x.value !== null &&
                                x.value !== ""
                            ) {
                                if(!offer.tags[cat.name]){
                                    offer.tags[cat.name] = {'formatted_value':'','tags':[]};
                                }
                                offer.tags[cat.name]['tags'].push({
                                    category: cat.name,
                                    value: x.value,
                                    formatted_value: x.formatted_value,
                                    type:x.type || '',
                                    order: x.order || null,
                                    start: x.start,
                                    end: x.end
                                });
                            }
                        })
                    }


                });
                params.offers.push(offer);
                params.separations.push({
                    category: "Offer",
                    value: tagItem.raw,
                    start: tagItem.start,
                    end: tagItem.end
                });
            });
            // TODO move separations category names to backend config/db
            this.partOfNextSet.forEach(part => {
                params.separations.push({
                    category: "Part of Next Set",
                    value: part.raw,
                    start: part.start,
                    order: part.order || null,
                    end: part.end
                });

                params.categorized_separations.push({
                    category: part.category,
                    value: part.raw,
                    start: 0,
                    order: part.order || null,
                    end: part.raw.length
                });
            });

            this.partOfAll.forEach(part => {
                params.separations.push({
                    category: "Part of All",
                    value: part.raw,
                    start: part.start,
                    order: part.order || null,
                    end: part.end
                });

                params.categorized_separations.push({
                    category: part.category,
                    value: part.raw,
                    start: 0,
                    order: part.order || null,
                    end: part.raw.length
                });
            });

            return params;
        },
        resetData() {
            this.$emit("setConfirm",false);
            this.$store.dispatch(RESET_DATA);
        },
        back(){
            if(this.fieldChange){
                this.showConfirmBack = true;
            }else{
                this.confirmBack();
            }
        },
        confirmBack(){
             this.$router.push({ name: 'list-offers' })
        }
    }
};
</script>

<style lang="scss" scoped>
  :deep(.v-card__actions) {
    height: 80px;
  }
</style>
