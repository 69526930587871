export default {
  process(html) {
    const parser = new DOMParser();

    html = parser.parseFromString(html, 'text/html');

    let tags = html.querySelectorAll("body>*");

    if (!tags.length) {
      return null;
    }

    let result = '';

    tags.forEach(tag => {
      let tableRows = tag.querySelectorAll("tr:not(tr tr)");
      if (tableRows.length) {
        tableRows.forEach(row => {
          let cols = row.querySelectorAll('td:not(tr tr td)');

          let rowContent = '';
          cols.forEach(col => {
            rowContent += (this._getElementContents(col) + ' ');
          });

          result += (rowContent.replace(/\s+/g, ' ').trim() + "\n");

          row.innerHTML = '';
        });
      }

      let tagContents = this._getElementContents(tag);
      if (tagContents) {
        result += (tagContents + "\n");
      }
    });

    return result;
  },

  _getElementContents(element) {
    let contents = (element.innerHTML.replace(/&nbsp;/g, ' ').replace(/<[^>]*>/g, ' ') + ' ').replace(/\s+/g, ' ').trim();

    if (contents.length !== 0) {
      return contents;
    }

    return '';
  }
};
