<template>
    <div>
        <v-card-text>

            <v-data-table
                v-model="selected"
                :headers="offerTableHeadersAll"
                :items="tableItems"
                item-key="uuid"
                :height="tableHeight"
                :show-select="showSelect"
                fixed-header
                single-expand
                :item-class="itemRowBackground"
                :show-expand="showExpand"
                :expanded.sync="expanded"
                @click:row="expand"
                disable-pagination
                hide-default-footer
            >
              <template v-slot:item.numbers="{ item }">
                    <span>{{item.number}}</span>
                </template>
                <template
                    v-for="(header, index) in offerTableHeaders"
                    v-slot:[getSlotName(header.value)]="props"
                >
                    <TPEditItem
                        :key="index"
                        :item="props.item"
                        :value="props.item[header.value].formatted_value"
                        :column="header.value"
                        :category="tagCategories[index]"
                        @dialog-open="expand(props.item)"
                        @apply="applyToEmpty(props.item,header.value,props.item[header.value].formatted_value)"
                        @save="save(props.item,header.value,props.item[header.value].formatted_value)"
                        @overwrite="overwrite"
                    ></TPEditItem>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <TPExtended
                        :colspan="headers.length"
                        :item="item"
                    ></TPExtended>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <v-icon
                        small
                        @click="showConfirmReset = true"
                        title="Reset data"
                        color="error"
                    >
                        mdi-restore
                    </v-icon>
                </template>

            </v-data-table>
        </v-card-text>
        <TPConfirm
            v-model="showConfirmReset"
            content="Reset offer data?"
            @confirm="resetData"
        ></TPConfirm>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { REMOVE_OFFER_TABLE_ITEM,ADD_OVERWRITE,SET_TAG_TO_OFFER,OVERWRITE_OFFER_TAG } from "@/store/vendorOfferSet.module";
import { RESET_OFFER_DATA,SET_FIELD_CHANGE } from "@/store/vendorOffer.module";
import validationMixin from "@/views/tagProcessor/Mixins/validation.mixin";
import TPEditItem from "@/views/tagProcessor/Output/Table/EditItem.vue";
import TPExtended from "@/views/tagProcessor/Output/Table/Extended.vue";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import OfferService from "@/common/offer.service";

export default {
    props: ["value", "showOnlyUuid","errorSet"],
    mixins: [validationMixin],
    components: {
        TPEditItem,
        TPExtended,
        TPConfirm
    },
    computed: {
        ...mapGetters([
            "tagCategories",
            "processedSet",
            "offerTableRows",
            "offerByUuid"
        ]),
        offerTableHeaders() {
            var headers = [];

            this.tagCategories.forEach(cat => {
                headers.push({
                    id: cat.id,
                    text: cat.name,
                    value: cat.name
                });
            });

            return headers;
        },
        offerTableHeadersAll() {
            var headers = [];
            headers.push({
                text: "№",
                value: "numbers",
                sortable: false
            });
            this.tagCategories.forEach(cat => {
                headers.push({
                    id: cat.id,
                    text: cat.name,
                    value: cat.name,
                    sort: (a, b) => {
                        if (a.formatted_value < b.formatted_value) {
                            return -1;
                        }
                        if (a.formatted_value > b.formatted_value) {
                            return 1;
                        }

                        return 0;
                    }
                });
            });

            headers.push({
                text: "Actions",
                value: "actions",
                sortable: false
            });

            return headers;
        },
        tableItems() {
            if (this.showOnlyUuid) {
                return this.offerTableRows.filter(
                    otr => otr.uuid === this.showOnlyUuid
                );
            }
            return this.offerTableRows.map((x,i) => {x.number = i+1; return x;});
        }
    },
    mounted() {
        if (this.showOnlyUuid) {
            this.expand(this.offerByUuid(this.showOnlyUuid));
            this.showSelect = false;
            this.showExpand = false;
        }
        setTimeout(x=>this.tableHeight = document.getElementsByTagName('body')[0].offsetHeight-254,100)
    },
    watch: {
        processedSet() {
            this.setItems();
        }
    },
    data() {
        return {
            selected: [],
            expanded: [],
            selectedExtendedOptions: {},
            showSelect: false,
            showExpand: true,
            showConfirmReset: false,
            tableHeight:300
        };
    },
    methods: {
        setItems() {
            this.$store.commit(SET_FIELD_CHANGE, true);
            this.offerTableRows.forEach(row => {
                if (
                    this.expanded.length === 0 &&
                    !this.isRowValid(this.tagCategories, row)
                ) {
                    this.expand(row);
                }
            });
        },
        getSlotName(value) {
            return "item." + value;
        },
        expand(item) {
            this.expanded = [];
            this.$set(this.expanded, this.expanded.length, item);
        },
        deleteItem(item) {
            this.$store.commit(SET_FIELD_CHANGE, true);
            this.$store.dispatch(REMOVE_OFFER_TABLE_ITEM, item.uuid);
        },
        overwrite(column, value) {
            this.$store.dispatch(ADD_OVERWRITE, {
                value: value,
                formatted_value: value,
                category: column,
                type:'Overwrite',
                onlyEmpty:false,
                offer:{}
            });
        },
        applyToEmpty(offer,column,value) {
            this.$store.dispatch(ADD_OVERWRITE, {
                value: value,
                formatted_value: value,
                category: column,
                type:'Apply',
                onlyEmpty:true,
                offer:offer
            });
        },
        save(offer,column,value) {
            // this.$store.dispatch(OVERWRITE_OFFER_TAG, {
            //     category: column,
            //     offer:offer
            // });
        },
        resetData() {
            this.$store.dispatch(RESET_OFFER_DATA, this.tableItems[0]);
        },
        itemRowBackground(item) {
            return item.number && item.number === this.errorSet ? 'tr-selected' : '';
        }
    }
};
</script>

<style>
.tp-match {
    color: var(--v-primary-base);
}
.tr-selected {
  background-color: rgb(44, 215, 73)
}
</style>
