<template>
    <div>
        <v-card-text
            @mouseup.prevent="handleSelected"
            @mousedown="handleSelecting"
        >
            <component
                @removeSegment="removedSegment"
                :is="segmentSpec"
            ></component>
            <TPSegmentMenu
                :params="selectedSegmentOptions"
                :options="options"
                :type="type"
                @save="segmentMenuSave"
            >
                <template v-slot:menu-content="{ segmentData, tagValue }">
                    <slot
                        name="menu-content"
                        v-bind:segment-data="segmentData"
                        v-bind:tag-value="tagValue"
                    ></slot>
                </template>
            </TPSegmentMenu>
        </v-card-text>
    </div>
</template>

<script>
import TPSegmentMenu from "@/views/tagProcessor/Output/Text/SegmentMenu.vue";
import TPSegment from "@/views/tagProcessor/Output/Text/Segment.vue";
import SelectionService from "@/common/selection.service";
import { SET_FIELD_CHANGE } from "@/store/vendorOffer.module";

const DISABLE_SELECTION_CLASS = " disable-selection";

export default {
    name: "SegmentText",
    props: {
        parts: {
            type: String,
            default: ""
        },
        text: {
            type: String
        },
        options: {
            type: Array
        },
        type:{
            type:String,
            default:"1"
        }
    },
    computed: {
        segmentSpec() {
            return {
                template: `<div class="segment-text text--primary"><div>${this.parts}</div><div>${this.text}</div></div>`,
                components: {
                    tpsegment: TPSegment
                },
                methods: {
                    removeSegment(type, uuid) {
                        this.$emit("removeSegment", type, uuid);
                    }
                }
            };
        }
    },
    components: {
        TPSegmentMenu
    },
    data() {
        return {
            selectedSegmentOptions: {}
        };
    },
    methods: {
        handleSelecting() {
            document.body.className = document.body.className.replace(
                DISABLE_SELECTION_CLASS,
                ""
            );
            document.body.className += DISABLE_SELECTION_CLASS;
        },
        handleSelected(e) {
            document.body.className = document.body.className.replace(
                DISABLE_SELECTION_CLASS,
                ""
            );

            let selection = SelectionService.getObject();

            if (selection !== null) {
                clearTimeout(timeout);
                let timeout = setTimeout(() => {
                    this.selectedSegmentOptions = {
                        selection: selection,
                        item: {},
                        event: e
                    };
                }, 100);
            }
        },
        segmentMenuSave(data, selection,type) {
            this.$store.commit(SET_FIELD_CHANGE, true);
            this.$emit("add", data, selection,type);
        },
        removedSegment(type, uuid) {
            this.$store.commit(SET_FIELD_CHANGE, true);
            let text = SelectionService.clearById(this.text, `${uuid}`);
            this.$emit("remove", text, type, uuid);
        }
    }
};
</script>
