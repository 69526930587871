<template>
      <TPText
        class="scrollable-div"
        :style="{ height: textHeight + 'px' }"
      ></TPText>
</template>

<script>


import TPText from "@/views/tagProcessor/Output/Text/Text.vue";

export default {
  components: {
    TPText
  },
  mounted() {
    setTimeout(
      (x) =>
        (this.textHeight = parseInt(
          document.getElementsByTagName("body")[0].offsetHeight - 224
        )),
      100
    );
  },
  data() {
    return {
      textHeight: 150
    };
  },
  methods: {


  },
};
</script>
