<template>
    <span :class="segmentData.type.name === 'Offer' ? 'break-line' : ''">
        <v-menu
            v-model="menu"
            bottom
            right
            transition="scale-transition"
            offset-y
            :close-on-content-click="false"
            z-index="98"
        >
            <template v-slot:activator="{ on }">
                <button v-on="on">
                    <v-chip
                        pill
                        class="ma-1"
                        style="cursor: inherit"
                        close
                        :color="segmentData.type.color || 'primary'"
                        text-color="white"
                        @click:close="$emit('remove', segmentData.type, uuid)"
                        @mouseover="handleMouseOver"
                        @mouseleave="handleMouseLeave"
                        :title="segmentData.type.name"
                    >
                        <v-chip
                            style="cursor: inherit"
                            class="ma-2"
                            x-small
                            label
                            :color="labelColor"
                        >
                            {{ labelValue }}
                        </v-chip>
                        <small
                            class="label-content-overflow"
                            >{{ decodedContent }}</small
                        >
                    </v-chip>
                </button>
            </template>
            <v-card v-if="segmentData.type.menuComponent">
                <component
                    :text="decodedContent"
                    :uuid="uuid"
                    :is="menuComponent"
                ></component>
            </v-card>
        </v-menu>
    </span>
</template>

<script>
import SelectionService from "@/common/selection.service";

export default {
    props: ["segmentData", "content", "uuid"],
    computed: {
        menuComponent() {
            if (!this.segmentData.type.menuComponent) {
                return {};
            }
            return () =>
                import(
                    `@/views/tagProcessor/Output/Text/Menu/${this.segmentData.type.menuComponent}.vue`
                );
        },
        decodedContent() {
            return SelectionService.decodeHtml(this.content);
        }
    },
    mounted() {
        this.labelValue = this.segmentData.secondary
            ? this.segmentData.secondary.name
            : this.segmentData.type.name;
    },
    data() {
        return {
            menu: false,
            labelColor: "none",
            labelValue: null
        };
    },
    methods: {
        handleMouseOver() {
            this.labelColor = "primary";
        },
        handleMouseLeave() {
            this.labelColor = "none";
        }
    }
};
</script>
