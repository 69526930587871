<template>
    <div>
        <v-card :disabled="isProcessing" :loading="isProcessing">
            <template v-slot:progress>
                <v-progress-linear
                    absolute
                    color="success"
                    height="4"
                    indeterminate
                ></v-progress-linear>
            </template>
                <v-tabs
                    v-model="tab"
                    background-color="deep-purple accent-4"
                    left
                    dark
                    icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                    Raw Content
                    </v-tab>

                    <v-tab href="#tab-2" v-show="showOutput">
                    Processed Offers
                    </v-tab>
                    <v-tab href="#tab-3" v-show="showOutput">
                    Table View
                    </v-tab>
                      <v-spacer></v-spacer>


                        <div class="buttons-tab">
                               <v-menu offset-y v-if="tab === 'tab-1'">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <TPAddContact
                                            @processing="handleContactProcessing"
                                            @processed="handleContactProcessed"
                                            @new-contact="handleNewContact"
                                        ></TPAddContact>
                                    </v-list>
                                </v-menu>

                                <v-btn v-if="tab === 'tab-2' || tab === 'tab-3'"
                                    @click="showConfirmReset = true"
                                    icon
                                    title="Reset data"
                                >

                                    <v-icon>mdi-restore</v-icon>
                                </v-btn>
                        </div>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                                <TPInput
                                    :id="id"
                                    :isAutoProcess="isAutoProcess"
                                    :rawData="rawData"
                                    :stored="stored"
                                    @processing="processed = false"
                                    @processed="handleProcessed"
                                    @processFailed="showOutput = false"
                                    :updateId="id"
                                ></TPInput>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat>
                                <TPOutput
                                    id="tpOutput"
                                    v-show="showOutput"
                                    :processed="processed"
                                ></TPOutput>
                                 <TPStoreOfferActions
                                    :showConfirmReset="showConfirmReset"
                                    :changeFieldValue="changeFieldValue"
                                    @setConfirm="handleShowConfirm"
                                    @stored="handleStoredOfferSet"
                                    @toggleTab="handleToggleTab"
                                    @showError="handleErrorMessage"
                                    @processing="handleProcessing"
                                    :updateId="id"
                                ></TPStoreOfferActions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat>
                               <TPTable :errorSet="errorRow"></TPTable>
                                <TPStoreOfferActions
                                    :showConfirmReset="showConfirmReset"
                                    @setConfirm="handleShowConfirm"
                                    @stored="handleStoredOfferSet"
                                    @toggleTab="handleToggleTab"
                                    @showError="handleErrorMessage"
                                    @processing="handleProcessing"
                                    :updateId="id"
                                ></TPStoreOfferActions>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>

           <v-snackbar v-model="errorSnack" :timeout="6000" color="error">
            {{ errorMessage }}
            <v-btn text @click="errorSnack = false">Close</v-btn>
        </v-snackbar>
        <v-snackbar v-model="snack" :timeout="3000" color="success">
            Success
            <v-btn text @click="snack = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import TPInput from "@/views/tagProcessor/Input/Input.vue";
import TPOutput from "@/views/tagProcessor/Output/Output.vue";
import TPTable from "@/views/tagProcessor/Output/Table/Table.vue";
import TPStoreOfferActions from "@/views/tagProcessor/Output/Text/StoreOfferActions.vue";
import TPAddContact from "@/views/tagProcessor/Input/AddContact";
import { EDIT_OFFER } from "@/store/vendorOfferSet.module";
import { SEARCH_TAG_CATEGORIES } from "@/store/tagCategory.module";
import { GET_VALIDATION_RULES } from "@/store/validationRule.module";
import { GET_TAG_CATEGORY_TYPES } from "@/store/tagCategoryType.module";
import { GET_TAG_CATEGORY_USES } from "@/store/tagCategoryUse.module";
import { SET_FIELD_CHANGE } from "@/store/vendorOffer.module";
import moment from "moment";

export default {
    props:['id'],
    name: "TagProcessor",
    components: {
        TPTable,
        TPInput,
        TPOutput,
        TPAddContact,
        TPStoreOfferActions
    },
    mounted() {
        this.$store.dispatch(SEARCH_TAG_CATEGORIES,{type:1});
        this.$store.dispatch(GET_VALIDATION_RULES);
        this.$store.dispatch(GET_TAG_CATEGORY_USES);
        this.$store.dispatch(GET_TAG_CATEGORY_TYPES).then(response => {
            if(this.id){
                this.isProcessing=true;
                this.isAutoProcess = false;
                this.$store.dispatch(EDIT_OFFER,this.id).then(x=>{

                    this.handleProcessed();
                    this.rawData = x.details;
                    this.rawData.dataKey=1;
                    this.rawData.expireDataKey=1;
                    this.isProcessing=false;
                }).finally(x => this.isProcessing=false)
            }

        });
    },
    data() {
        return {
            tab:null,
            sgnt: null,
            showOutput: false,
            processed: false,
            stored: false,
            snack: false,
            isAutoProcess: true,
            showConfirmReset:false,
            errorSnack:false,
            errorMessage:"",
            changeFieldValue:false,
            rawData: {
                company_contact_id: null,
                import_type: null,
                date: moment().format("YYYY-MM-DD"),
                expire_date: moment()
                    .add(7, "days")
                    .format("YYYY-MM-DD"),
                raw: null,
                file: null
            },
            isProcessing:false,
            errorRow:null
        };
    },
    methods: {
        handleProcessed() {
            this.stored = false;
            this.showOutput = true;
            this.processed = true;
            this.tab = 'tab-2';
        },
        handleStoredOfferSet() {
            this.processed = this.id?true:false;
            this.stored = true;
            this.showOutput = this.id?true:false;
            this.snack = true;
            this.tab = 'tab-1';
            this.$store.commit(SET_FIELD_CHANGE, false);


        },
        handleShowConfirm(){
            this.showConfirmReset=false;
        },
        handleContactProcessing() {
            this.processed = false;
        },
        handleToggleTab(value) {
            this.tab = value;
        },
        handleContactProcessed() {
            this.processed = true;
        },
        handleNewContact(id) {
            this.rawData.company_contact_id = id;
        },
        handleErrorMessage(value) {
            this.errorMessage = value.error;
            this.errorSnack = true;
            this.errorRow = value.offer;
        },
        handleProcessing(value) {
            this.isProcessing = value;
        }
    }
};
</script>

<style lang="scss" scoped>
  :deep(.v-tabs-items) {
    overflow: initial;
  }
</style>