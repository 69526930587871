<template>
    <div>
        <v-card :loading="isLoading">
            <v-card-text>
                <v-text-field
                    v-model="newCategory.name"
                    :rules="[validations.required()]"
                    label="Category Name"
                ></v-text-field>
                <v-select
                    v-model="newCategory.tag_category_type_id"
                    :items="tagCategoryTypes"
                    item-text="name"
                    item-value="id"
                    label="Category Type"
                    :rules="[validations.required()]"
                ></v-select>
                <v-select
                    v-model="sortType"
                    :items="sortTypes"
                    item-text="name"
                    item-value="name"
                    label="Sort Type"
                    :rules="[validations.required()]"
                ></v-select>
                <v-select
                    v-model="sortField"
                    :items="tagCategories"
                    item-text="name"
                    item-value="name"
                    label="Sort Type"
                    return-object
                    :rules="[validations.required()]"
                ></v-select>
                  <v-select
                    v-model="newCategory.tag_category_uses"
                    :items="tagCategoryUses"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Use For"
                    multiple
                ></v-select>
                <div v-for="use in newCategory.tag_category_uses"  :key="use.id">
                      <v-select
                    v-model="newCategory.validation_rules[use.id]"
                    :items="validationRules"
                    item-text="rule"
                    item-value="id"
                    return-object
                    :label="'Validations '+ use.name"
                    multiple
                ></v-select>
                </div>

                <v-checkbox
                    v-show="false"
                    v-model="stored"
                    :rules="[validations.required()]"
                ></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="success"
                    :disabled="!isValidForm"
                    text
                    @click="add"
                >
                    Add Category and Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar
            v-model="snack"
            :timeout="6000"
            :color="errorMessage ? 'error' : 'success'"
        >
            {{ errorMessage || "Success" }}
            <v-btn text @click="snack = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import validations from "@/views/partials/form/Validations.vue";
import { STORE_TAG_CATEGORY } from "@/store/tagCategory.module";
import { SET_NEW_TAG_CATEGORY_TO_OFFERS } from "@/store/vendorOfferSet.module";

export default {
    computed: {
        ...mapGetters(["tagCategories", "validationRules", "tagCategoryTypes","tagCategoryUses"]),
        isValidForm() {
            return (
                this.newCategory.name &&
                this.newCategory.sort &&
                this.newCategory.tag_category_type_id
            );
        }
    },
    watch: {
        sortField(value) {
            this.newCategory.sort =
                this.sortType === "After" ? value.sort + 0.5 : value.sort - 0.5;
        },
        sortType(value) {
            if (value === "After" && this.sortField) {
                this.newCategory.sort = this.sortField.sort + 0.5;
            } else if (value === "Before" && this.sortField) {
                this.newCategory.sort = this.sortField.sort - 0.5;
            }
        }
    },
    data() {
        return {
            isLoading: false,
            snack: false,
            errorMessage: null,
            stored: false,
            validations: validations,
            newCategory: {
                name: null,
                sort: null,
                tag_category_type_id: null,
                validation_rules: {},
                tag_category_uses: [{name:'offer',id:1}],
            },
            sortType: "After",
            sortField: null,
            sortTypes: [
                {
                    name: "After"
                },
                {
                    name: "Before"
                }
            ]
        };
    },
    methods: {
        add() {
            this.isLoading = true;
            this.$store
                .dispatch(SET_NEW_TAG_CATEGORY_TO_OFFERS, this.newCategory.name)
                .then(() => {
                    this.$store
                        .dispatch(STORE_TAG_CATEGORY, this.newCategory)
                        .then(cat => {
                            this.$emit("store", cat);
                            this.errorMessage = null;
                        })
                        .catch(e => {
                            console.error(e);
                            this.errorMessage = this.$t(
                                "GENERAL.UNEXPECTED_ERROR"
                            );
                        })
                        .finally(() => {
                            this.snack = true;
                            this.isLoading = false;
                        });
                });
        },
        getName(use){
        }
    }
};
</script>
