<template>
    <div>
        <TPSegmentText
            :text="formattedSetText"
            :options="segmentOptions"
            @add="addSegment"
            @remove="removeSegment"
        >
            <template v-slot:menu-content="{ segmentData, tagValue }">
                <v-select
                    v-if="segmentData.type && segmentData.type.name !== 'Offer'"
                    label="Category"
                    :items="tagCategories"
                    item-text="name"
                    item-value="name"
                    return-object
                    v-model="segmentData.secondary"
                    :rules="[validations.required()]"
                >
                </v-select>

                <TPTagSuggest
                    v-if="
                        segmentData.secondary &&
                            (segmentData.secondary.tag_category_type === 'select' || segmentData.secondary.tag_category_type === 'autocomplete')
                    "
                    :tag-category="segmentData.secondary.name"
                    :tag-value="tagValue"
                    @match="selectSuggestedTag(segmentData, ...arguments)"
                ></TPTagSuggest>
            </template>
        </TPSegmentText>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import validations from "@/views/partials/form/Validations.vue";
import { SHOW_SNACK } from "@/store/snack.module";

import {
    SET_FORMATTED_SET_TEXT,
    SEGMENT_OPTIONS
} from "@/store/vendorOfferSet.module";
import TPSegmentText from "@/views/tagProcessor/Output/Text/SegmentText.vue";
import TPTagSuggest from "@/views/tagProcessor/Output/Table/TagSuggest.vue";

export default {
    computed: {
        ...mapGetters([
            "formattedSetText",
            "offerTableRows",
            "vendorOfferSet",
            "tagCategories"
        ])
    },
    components: {
        TPSegmentText,
        TPTagSuggest
    },
    data() {
        return {
            validations: validations,
            segmentOptions: SEGMENT_OPTIONS
        };
    },
    methods: {
        addSegment(data, selection) {
            if(typeof data.secondary === "object" && data.secondary !== null){
                    const isValid = validations.checkIsValid(
                        data.secondary,
                        selection.text
                    );
                    if (isValid !== true) {
                        this.$store.dispatch(SHOW_SNACK, {
                            message: isValid,
                            timeout: 200000,
                            type: "error"
                        });
                        return;
                    }
            }

            this.$store.dispatch(data.type.addAction, {
                selection: selection,
                data: data
            });
        },
        removeSegment(text, type, uuid) {
            this.$store.dispatch(type.removeAction, uuid);
            this.$store.commit(SET_FORMATTED_SET_TEXT, text);
        },
        selectSuggestedTag(data, tag) {
            data.suggestedTag = tag;
            const dataTypeName = data.type.name;
            data.type.name = null;
            data.type.name = dataTypeName;
        }
    }
};
</script>
