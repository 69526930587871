<template>
    <td :colspan="colspan">
        <TPSegmentText
            :parts="item.getPartsContent()"
            :text="item.expanded"
            :options="tagCategoriesWithAddNew"
            type="2"
            @add="addTag"
            @remove="removeTag"
        >
            <template v-slot:menu-content="{ segmentData, tagValue }">
                <TPNewCategory
                    v-if="  segmentData.type && segmentData.type.name === newFieldName "
                    @store="selectCategory(segmentData, ...arguments)"
                ></TPNewCategory>
                <TPTagSuggest
                    v-if=" segmentData.type && (segmentData.type.tag_category_type === 'select' || segmentData.type.tag_category_type === 'autocomplete')"
                    :tag-category="segmentData.type.name"
                    :tag-value="tagValue"
                    :opts="selectOptions(segmentData)"
                    @match="selectSuggestedTag(segmentData, ...arguments)"
                ></TPTagSuggest>
            </template>
        </TPSegmentText>
    </td>
</template>

<script>
import { mapGetters } from "vuex";
import {
    UPDATE_OFFER_TAG,
    SET_TAG_TO_ALL_OFFERS,
    REFRESH_OFFERS_WITH_PARTS
} from "@/store/vendorOfferSet.module";
import { ADD_NEW_FIELD_NAME } from "@/store/tagCategory.module";
import TPNewCategory from "@/views/tagProcessor/Output/Table/NewCategory.vue";
import TPTagSuggest from "@/views/tagProcessor/Output/Table/TagSuggest.vue";
import formatter from "@/common/formatter.service";
import validations from "@/views/partials/form/Validations.vue";
import { SHOW_SNACK } from "@/store/snack.module";

export default {
    props: ["colspan", "item"],
    computed: {
        ...mapGetters(["tagCategories", "offerTableRows"]),
        tagCategoriesWithAddNew() {
            var cats = JSON.parse(JSON.stringify(this.tagCategories));
            cats.push({
                name: ADD_NEW_FIELD_NAME
            });
            return cats;
        }
    },
    components: {
        TPSegmentText: () =>
            import("@/views/tagProcessor/Output/Text/SegmentText.vue"),
        TPNewCategory,
        TPTagSuggest
    },
    data() {
        return {
            newFieldName: ADD_NEW_FIELD_NAME,
            isDisabledToAll: false
        };
    },
    methods: {
        addTag(segmentData, selection,type) {

            const isValid = validations.checkIsValid(
                segmentData.type,
                selection.text
            );
            if (isValid !== true) {
                this.$store.dispatch(SHOW_SNACK, {
                    message: isValid,
                    timeout: 200000,
                    type: "error"
                });
                return;
            }

            var formattedValue = segmentData.suggestedTag || selection.text;
            formattedValue = formatter.formatBasedOnType(
                formattedValue,
                segmentData.type.tag_value_type
            );

            this.$store.dispatch(UPDATE_OFFER_TAG, {
                offer: this.item,
                value: selection.text,
                formatted_value: formattedValue,
                start: selection.realStart,
                end: selection.realEnd,
                category: segmentData.type.name,
                order:segmentData.realStart
            });

            if (type !== 'save') {
                if (segmentData.type.tag_category_type !== "number") {
                    this.$store.dispatch(SET_TAG_TO_ALL_OFFERS, {
                        value: selection.text,
                        formatted_value: formattedValue,
                        category: segmentData.type.name,
                        originalStart: selection.realStart,
                        order:selection.realStart,
                        offer:this.item,
                        saveType:type
                    });
                }
            }


        },
        removeTag(text, type, uuid) {
            if (type.removeAction) {
                this.$store.dispatch(type.removeAction, uuid);
            } else {
                this.item[type.name]['tags'] = this.item[type.name]['tags'].filter(x => x.uuid !== uuid);
                this.item[type.name]['formatted_value'] =  this.item[type.name]['tags'].map(d => d.formatted_value).filter(x => x).join('|');
                this.item.expanded = text;

                if(this.item[type.name]['tags'].length === 0){
                      this.$store.dispatch(REFRESH_OFFERS_WITH_PARTS);
                }
            }
        },
        selectCategory(data, category) {
            data.type = category;
        },
        selectSuggestedTag(data, tag) {

            data.suggestedTag = tag;
            const dataTypeName = data.type.name;
            data.type.name = null;
            data.type.name = dataTypeName;
        },
        selectOptions(category){
            // if(!category.type.opts){
                return null;
            // }

            // let options = [];
            // Object.keys(category.type.opts).forEach(k => {
            //     if(this.item[k]){
            //       options = options.concat(category.type.opts[k][this.item[k].formatted_value]);
            //     }
            // })

            // return options;
        }
    }
};
</script>
