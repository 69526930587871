<template>
    <div >
         <v-card :disabled="isProcessing" :loading="isProcessing">
            <template v-slot:progress>
                <v-progress-linear
                    absolute
                    color="success"
                    height="4"
                    indeterminate
                ></v-progress-linear>
            </template>

            <v-card-text v-show="showPanel">
                <v-form
                    @submit.prevent="store"
                    ref="inputForm"
                    v-model="validRawData"
                >
                    <v-row>
                        <v-col cols="12" sm="3">
                            <TPContactsAutocomplete
                                v-model="rawData.company_contact_id"
                                :defaults="rawData.company_contact"
                                :show-panel="showPanel"
                                @processing="handleProcessing"
                                @processed="handleProcessed"
                            ></TPContactsAutocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <TPDatePicker
                                label="Date"
                                v-model="rawData.date"
                                :key="rawData.dataKey || 0"
                                :rules="[validations.required()]"
                                @input="handleDateInput"
                            ></TPDatePicker>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <TPDatePicker
                                label="Expire Date"
                                :key="rawData.expireDataKey || 0"
                                v-model="rawData.expire_date"
                                :rules="[validations.required()]"
                            ></TPDatePicker>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-select
                            v-model="rawData.import_type"
                            :items="importTypes"
                            item-text="name"
                            item-value="id"
                            return-object
                            :label="$t('GENERAL.LABELS.IMPORT_TYPE')"
                            :disabled="isProcessing || id !== undefined"
                            @change="handleImportTypeChange"
                          />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-if="rawData.import_type?.slug === 'text'"
                                :rows="offerContentRows"
                                name="input-7-1"
                                label="Offer Content"
                                prepend-icon="mdi-text"
                                :disabled="isProcessing"
                                v-model="rawData.raw"
                                :rules="[validations.required()]"
                                @paste="onPaste"
                            ></v-textarea>
                            <template v-if="rawData.import_type?.slug === 'csv-file'">
                              <v-btn
                                color="primary"
                                @click="downloadCsvTemplate()"
                              >
                                {{ $t('GENERAL.LABELS.DOWNLOAD_TEMPLATE') }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                class="ml-2"
                                @click="$refs['csv-uploader'].click()"
                              >
                                {{ $t('GENERAL.LABELS.CHOOSE_FILE') }}
                              </v-btn>
                              <span class="ml-2 filename">
                                {{ rawData.file?.name }}
                              </span>
                              <span v-if="fileError" class="ml-2 filename text-danger">
                                {{ $t('GENERAL.LABELS.PLEASE_CHOOSE_FILE') }}
                              </span>
                              <input
                                ref="csv-uploader"
                                class="d-none"
                                type="file"
                                accept=".csv"
                                @change="uploadCsv"
                              />
                            </template>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                rows=2
                                name="input-7-1"
                                label="Notes"
                                prepend-icon="mdi-text"
                                :disabled="isProcessing"
                                v-model="rawData.notes"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <div>
            <v-card-actions v-if="showPanel">

                <v-switch
                    v-model="isAutoProcessEnabled"
                    hide-details
                    label="Auto process on paste"
                    @change="handleIsAutoProcessEnabledChange($event)"
                ></v-switch>
                <v-switch
                    v-show="rawData.import_type?.slug === 'text'"
                    v-model="isTableRecognitionEnabled"
                    class="ml-7"
                    hide-details
                    label="Enable table recognition"
                    @change="handleIsTableRecognitionEnabledChange($event)"
                ></v-switch>
                <v-spacer></v-spacer>
                   <v-btn
                    v-if="updateId"
                    color="warning"
                    v-on:click="back()"
                    >Back</v-btn
                >
                <v-btn color="primary" @click="store">Process</v-btn>
            </v-card-actions>
            </div>

        </v-card>
           <TPConfirm
            v-model="showConfirmBack"
            content="All current changes will be lost."
            @confirm="confirmBack"
            ></TPConfirm>
    </div>


</template>

<script>
import { mapGetters } from "vuex";
import { PROCESS_VENDOR_OFFER_SET } from "@/store/vendorOfferSet.module";
import validations from "@/views/partials/form/Validations.vue";
import TPDatePicker from "@/views/partials/form/DatePicker.vue";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import parseHtmlTableService from "@/common/parseHtmlTable.service.js";
import userStateService from "@/common/userState.service.js";
import moment from "moment";
import { RESET_DATA } from "@/store/vendorOfferSet.module";
import { GET_VENDOR_OFFER_SET_IMPORT_TYPES } from "@/store/vendorOfferSetImportType.module";

import TPContactsAutocomplete from "./ContactsAutocomplete";
import { SHOW_SNACK } from "@/store/snack.module";
import { API_URL, API_PATH } from "@/common/config";

export default {
    props: ["id", "stored","rawData","isAutoProcess","updateId"],
    components: {
        TPDatePicker,
        TPConfirm,
        TPContactsAutocomplete
    },
    computed: {
        ...mapGetters([
            "fieldChange"
        ])
    },
    watch: {
        stored(value) {
            if (value) {
                // this.showPanel = value;
            }
        },
        isAutoProcess(value){
            this.isAutoProcessEnabled = value;
        },

    },
    mounted(){
       this.fetchImportTypes();

        setTimeout(x=>{
            this.offerContentRows = parseInt(( document.getElementsByTagName('body')[0].offsetHeight-509)/22.23)
        },100)
    },
    data() {
        return {
            offerContentRows: 12,
            validations: validations,
            showPanel: true,
            processed: false,
            expireDatePickerKey: 0,
            validRawData: false,
            showConfirm: false,
            isProcessing: false,
            showConfirmBack:false,
            selectedFileName: '',
            fileError: false,
            importTypes: [],
            isAutoProcessEnabled:
              userStateService.getState(
                'add-vendor-offer.auto-process-enabled',
                true
              ),
            isTableRecognitionEnabled:
              userStateService.getState(
                'add-vendor-offer.table-recognition-enabled',
                true
              ),
        };
    },
    methods: {
        fetchImportTypes() {
          this.$store.dispatch(GET_VENDOR_OFFER_SET_IMPORT_TYPES)
            .then(response => {
              this.importTypes = response;

              if (!this.rawData.import_type_id) {
                let importType = this.importTypes.find(importType => importType.slug === 'text');

                if (importType) {
                  this.rawData.import_type_id = importType.id;
                  this.rawData.import_type = importType;
                }
              }
            })
            .catch(error => {
              this.$store.dispatch(SHOW_SNACK, {
                type: 'error',
                message: this.$t('GENERAL.UNEXPECTED_ERROR')
              });
            });
        },

        downloadCsvTemplate() {
          location.href = `${API_URL}${API_PATH}vendorOfferSets/download-csv-import-template`;
        },

        uploadCsv(event) {
          let file = event.target.files[0];

          if(!file){
              return;
          }

          this.rawData.file = file;

          this.fileError = false;

          this.$forceUpdate();

          if (this.isAutoProcessEnabled) {
                setTimeout(() => {
                    this.store();
                }, 4);
            }
        },

        onPaste(event) {
          const clipHTMLText = event.clipboardData.getData('text/html');

          setTimeout(() => {
            if (clipHTMLText && this.isTableRecognitionEnabled) {
              let parsedData = parseHtmlTableService.process(clipHTMLText);

              if (parsedData) {
                this.rawData.raw = parsedData;
              }
            }

            if (this.isAutoProcessEnabled) {
                setTimeout(() => {
                    this.store();
                }, 4);
            }
          }, 50);
        },
        handleDateInput(date) {
            this.rawData.expire_date = moment(date)
                .add(7, "days")
                .format("YYYY-MM-DD");
            this.rawData.expireDataKey++;
        },
        handleImportTypeChange(importType) {
          this.rawData.import_type_id = importType.id;
        },
        store() {
            this.$refs.inputForm.validate();
            this.processed = false;
            if (!this.validRawData) {
                return;
            }

            if (this.rawData.import_type?.slug !== 'text' && !this.rawData.file) {
              this.fileError = true;

              this.$forceUpdate();

              return;
            }

            if (this.processed && !this.stored) {
                this.showConfirm = true;
                return;
            }
            this.$store.dispatch(RESET_DATA);

            this.isProcessing = true;
            this.$emit("processing");

            this.$store
                .dispatch(PROCESS_VENDOR_OFFER_SET, this.rawData)
                .then((data) => {
                  this.rawData.raw = data.data.set.text;

                    this.processed = true;
                    this.$emit("processed");
                    this.$nextTick(() => {
                        // this.showPanel = false;
                    });
                })
                .catch((response) => {
                      this.handleException(response)
                      this.$emit("processFailed");
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
        confirmProcess() {
            this.processed = false;
            this.store();
        },
        handleProcessing() {
            this.isProcessing = true;
        },
        handleProcessed() {
            this.isProcessing = false;
        },
        handleIsAutoProcessEnabledChange(value) {
          userStateService.setState(
            'add-vendor-offer.auto-process-enabled',
            value
          );
        },
        handleIsTableRecognitionEnabledChange(value) {
          userStateService.setState(
            'add-vendor-offer.table-recognition-enabled',
            value
          );
        },
        back(){
            if(this.fieldChange){
                this.showConfirmBack = true;
            }else{
                this.confirmBack();
            }
        },
        confirmBack(){
             this.$router.push({ name: 'list-offers' })
        }
    }
};
</script>

<style lang="scss" scoped>
  .filename {
    font-size: 16px;
  }
</style>
